
import { mapGetters } from 'vuex'
export default {
    name: 'Theme3MenuBar',
    data() {
        return {
            menuIndex: null,
            showMenuTimeout: null,
            cursorOnMenu: false,
        }
    },
    computed: {
        ...mapGetters({
            menubars: 'cms/menubars',
        }),
    },
    methods: {
        onFocusMenu(index) {
            if (index === null) {
                const TIME_TO_HIDE_MENU = 500
                this.showMenuTimeout = setTimeout(() => {
                    if (!this.cursorOnMenu) {
                        this.menuIndex = index
                    }
                }, TIME_TO_HIDE_MENU)
            } else {
                if (this.showMenuTimeout) {
                    clearTimeout(this.showMenuTimeout)
                }
                this.menuIndex = index
            }
        },
        onMouseLeaveMenuList() {
            this.cursorOnMenu = false
            this.menuIndex = null
        },
    },
}
