
export default {
    name: 'WithdrawalConfirmDeclineDialog',
    props: {
        value: {
            type: Boolean,
            default: false,
        },
        successful: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        modal: {
            get() {
                return this.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
        player() {
            return this.$store.state.player.player
        },
    },
}
