
import { mapState } from 'vuex'
export default {
    data() {
        return {
            route: '/',
        }
    },
    computed: {
        ...mapState({
            isLogin: (state) => state.settings.isLogin,
        }),
    },
    watch: {
        '$nuxt.$route.path'(value) {
            this.route = value
        },
    },
    mounted() {
        this.route = this.$nuxt.$route.path
    },
    methods: {
        isActive(route) {
            return this.route === route
        },
        clickCenterMenu() {
            this.$router.push('/')
        },
    },
}
